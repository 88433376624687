.good-dot {
    height: 25px;
    width: 25px;
    background-color: rgb(28, 187, 36);
    border-radius: 50%;
    display: inline-block;
}

.ewarning-dot {
    height: 25px;
    width: 25px;
    background-color: rgb(233, 229, 18);
    border-radius: 50%;
    display: inline-block;
}

.failing-dot{
    height: 25px;
    width: 25px;
    background-color: rgb(218, 140, 24);
    border-radius: 50%;
    display: inline-block;
}

.danger-dot {
    height: 25px;
    width: 25px;
    background-color: rgb(184, 14, 14);
    border-radius: 50%;
    display: inline-block;
}

table td.tablaNum { text-align: end;}
